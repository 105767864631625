import React from 'react';

export default function Sidecard({ title, children }) {
  return (
    <div
      data-sal="slide-up"
      data-sal-delay="200"
      className="relative z-50 p-8 mt-10 bg-brokenwhite"
    >
      <h3 className="mb-6 text-xl font-bold font-accent">{title}</h3>
      {typeof children === 'string' && <p className="font-body">{children}</p>}
      {typeof children !== 'string' && children}
    </div>
  );
}
