import React from 'react';

export default function DotSpacer({ mb, mt, className }) {
  if (mb) {
    return (
      <section
        className={`dotspacer w-full mb-12 md:mb-24 ${className}`}
      ></section>
    );
  } else if (mt) {
    return (
      <section
        className={`dotspacer w-full mt-12 md:mt-24 ${className}`}
      ></section>
    );
  } else {
    return (
      <section
        className={`dotspacer w-full my-12 md:my-24 ${className}`}
      ></section>
    );
  }
}
