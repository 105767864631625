import React from 'react';
import Container from './layouts/Container';
import Button from './molecules/Button';
import Text from './type/Text';
import Title from './type/Title';

export default function CallToAction({
  style,
  title,
  text,
  buttontext,
  buttonurl,
  html,
}) {
  const createMarkup = () => {
    return {
      __html: html,
    };
  };

  if (style === 'normal') {
    return (
      <section className="relative flex flex-col items-start justify-center pb-10 bg-red-pink pt-14">
        <Container className="flex justify-between">
          <div className="w-full sm:w-1/2">
            <Title title={title} />
            <Text text={text} />
            <Button text={buttontext} url={buttonurl} />
          </div>
        </Container>
        <img
          src="/toren_lang.png"
          alt="De lange toren"
          className="absolute right-0 z-10 hidden object-contain -bottom-5 w-96 sm:block"
        />
      </section>
    );
  } else if (style === 'bigtower') {
    return (
      <section className="relative flex flex-col items-start justify-center pb-10 bg-red-pink pt-14">
        <Container className="flex justify-between">
          <div className="w-full md:w-1/2">
            <Title title={title} />
            <Text text={text} />
            <Button text={buttontext} url={buttonurl} />
          </div>
        </Container>
        <img
          src="/toren_breed.png"
          alt="De brede toren"
          className="absolute right-0 -bottom-5 z-10 hidden w-[500px] object-contain md:block"
        />
      </section>
    );
  } else if (style === 'centered') {
    return (
      <section className="relative flex flex-col items-start justify-center pb-10 bg-red-pink pt-14">
        <Container className="flex justify-center">
          <div className="w-full max-w-xl text-center">
            <Title title={title} />
            <Text text={text} />
            <Button text={buttontext} url={buttonurl} />
          </div>
        </Container>
      </section>
    );
  } else if (style === 'customhtml') {
    return (
      <section
        className="w-full"
        dangerouslySetInnerHTML={createMarkup()}
      ></section>
    );
  }
}
