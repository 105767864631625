import React from 'react';

export default function FancyLink({ link, target, title }) {
  //Todo: make dynamic for internal links
  return (
    <a href={link} target={target} className="block fancylink text-[15px]">
      {title}
    </a>
  );
}
