import React from 'react';

export default function WoodSpacer({ small, className }) {
  if (small) {
    return <section className={className + " spacer w-full h-52 relative z-20"}></section>;
  } else {
    return (
      <section className={className + " spacer w-full h-64 md:h-96 relative z-20"}></section>
    );
  }
}
