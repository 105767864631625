import React from 'react';
import Page from '../components/layouts/Page';
import Sidecard from '../components/molecules/Sidecard';
import FancyLink from '../components/molecules/FancyLink';
import ImageSlider from '../components/molecules/ImageSlider';
import CallToAction from '../components/CallToAction';
import WoodSpacer from '../components/molecules/WoodSpacer';
import DotSpacer from '../components/molecules/DotSpacer';
import Container from '../components/layouts/Container';
import Title from '../components/type/Title';
import Text from '../components/type/Text';
import Button from '../components/molecules/Button';
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { graphql } from 'gatsby';

export default function contentHalfhalfDotsContentSlider({ data }) {
  const content = data.wpPage;
  const innerContent =
    data.wpPage.paginaTemplate.contentHalfhalfDotsContentSlider;

  const allJsonBreadcrumbs = [];
  const allBreadcrumbs = [];

  if (content.seo.breadcrumbs !== null) {
    content.seo.breadcrumbs.forEach((breadcrumb, index) => {
      allJsonBreadcrumbs.push({
        position: index + 1,
        name: breadcrumb.name,
        item: 'https://graafdaron.nl' + breadcrumb.item,
      });
      allBreadcrumbs.push({
        name: breadcrumb.name,
        item: breadcrumb.item,
      });
    });
  }

  return (
    <>
      {content.seo.breadcrumbs ? (
        <>
          <GatsbySeo
            title={content.seo.seoData.title}
            description={content.seo.seoData.description}
            openGraph={{
              type: 'website',
              url: 'https://graafdaron.nl/' + content.slug,
              title: content.seo.seoData.title,
              description: content.seo.seoData.description,
            }}
          />
          <BreadcrumbJsonLd itemListElements={allJsonBreadcrumbs} />
        </>
      ) : (
        ''
      )}
      <Page
        breadcrumbs={
          <>
            <>
              {allBreadcrumbs.map((breadcrumb) => {
                return (
                  <Breadcrumb title={breadcrumb.name} link={breadcrumb.item} />
                );
              })}
            </>
          </>
        }
        sidebar={false}
        title={content.title}
        html={innerContent.text}
        bottom={
          <>
            <Container>
              <div className="flex flex-wrap lg:flex-nowrap">
                <section className="w-full p-8 bg-red-pink lg:mr-5 lg:w-1/2">
                  <Title title={innerContent.halfHalf.half1.title} />
                  <Text text={innerContent.halfHalf.half1.text} />
                  <div className="grid w-full grid-cols-2 my-8 md:w-3/4">
                    {innerContent.halfHalf.half1.fancylinks &&
                      innerContent.halfHalf.half1.fancylinks.map((link) => {
                        return (
                          <FancyLink
                            link={link.link.url}
                            target={link.link.target}
                            title={link.link.title}
                          />
                        );
                      })}
                  </div>
                  <Button
                    text={innerContent.halfHalf.half1.button.title}
                    url={innerContent.halfHalf.half1.button.url}
                  />
                </section>
                <section className="relative z-40 w-full p-8 mt-5 bg-brokenwhite lg:mt-0 lg:w-1/2">
                  <Title title={innerContent.halfHalf.half2.title} />
                  <Text text={innerContent.halfHalf.half2.text} />
                  <div className="grid w-full grid-cols-2 my-8 md:w-3/4">
                    {innerContent.halfHalf.half2.fancylinks &&
                      innerContent.halfHalf.half2.fancylinks.map((link) => {
                        return (
                          <FancyLink
                            link={link.link.url}
                            target={link.link.target}
                            title={link.link.title}
                          />
                        );
                      })}
                  </div>
                  <Button
                    text={innerContent.halfHalf.half2.button.title}
                    url={innerContent.halfHalf.half2.button.url}
                  />
                </section>
              </div>
              <DotSpacer className="relative z-10" />
              <div className="relative w-full">
                <div className="w-full lg:w-8/12">
                  <Title title={innerContent.textAndImage.title} />
                  <Text text={innerContent.textAndImage.text} />
                  <Button
                    className="mt-10 mb-20"
                    text={innerContent.textAndImage.button.title}
                    url={innerContent.textAndImage.button.url}
                  />
                </div>
                <img
                  src="/explorer.png"
                  alt="explorer jongen die hangt aan een touw"
                  className="absolute right-0 z-30 hidden -top-72 lg:block"
                />
              </div>
            </Container>
            <ImageSlider />
            <WoodSpacer small={true} />
          </>
        }
      />
    </>
  );
}

export const query = graphql`
  query ContentHalfhalfDotsContentSliderQuery($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      paginaTemplate {
        contentHalfhalfDotsContentSlider {
          text
          halfHalf {
            half1 {
              button {
                target
                title
                url
              }
              fancylinks {
                link {
                  title
                  url
                  target
                }
              }
              text
              title
            }
            half2 {
              button {
                target
                title
                url
              }
              fancylinks {
                link {
                  target
                  title
                  url
                }
              }
              text
              title
            }
          }
          textAndImage {
            image {
              localFile {
                childImageSharp {
                  id
                }
              }
            }
            text
            title
            button {
              target
              title
              url
            }
          }
        }
      }
      seo {
        seoData {
          description
          title
        }
        breadcrumbs {
          item
          name
        }
      }
    }
  }
`;
