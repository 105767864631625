import React from 'react';
import Layout from './Layout';
import Container from './Container';
import Title from '../type/Title';

export default function Page({
  title,
  html,
  jsx,
  aside,
  stickyaside,
  sidebar,
  bottom,
  breadcrumbs,
}) {
  function createMarkup() {
    return { __html: html };
  }

  if (sidebar) {
    return (
      <Layout small={true}>
        <Container className="relative py-8 md:py-16">
          {/* <img
            src="/shoeprint.png"
            className="absolute top-1/2 right-0 w-1/4 -translate-y-1/2 scale-x-[-1] opacity-10"
          /> */}
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">{breadcrumbs}</ol>
          </nav>
          <section className="flex flex-wrap items-start md:flex-nowrap">
            <div className="w-full lg:w-9/12">
              <Title h1={true} title={title} />
              <div
                className="blog-content-inner prose !w-full max-w-full overflow-hidden md:max-w-[80%]"
                dangerouslySetInnerHTML={createMarkup()}
              ></div>
              <div>{jsx}</div>
            </div>
            <aside
              className={`w-full md:z-30 md:w-1/2 lg:w-3/12 ${
                stickyaside ? '' : 'sticky top-20'
              }`}
            >
              {aside}
            </aside>
          </section>
        </Container>
        {bottom}
      </Layout>
    );
  } else {
    return (
      <Layout small={true}>
        <Container className="py-8 md:py-16">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">{breadcrumbs}</ol>
          </nav>
          <section>
            <div className="w-full lg:w-9/12">
              <Title title={title} />
              <div
                className="max-w-full prose"
                dangerouslySetInnerHTML={createMarkup()}
              ></div>
            </div>
          </section>
        </Container>
        {bottom}
      </Layout>
    );
  }
}
