import React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ImageSlider() {
  const data = useStaticQuery(graphql`
    query {
      wp {
        aparteInstellingen {
          globalSettings {
            imageslider {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);

  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  function graphQlImagesToNormalArray (images) {
    const array = [];
    images.forEach((image) => {
      array.push(image.localFile);
    });
    return array;
  }

  const chosenOnes = getRandom(
    graphQlImagesToNormalArray(data.wp.aparteInstellingen.globalSettings.imageslider),
    5,
  );

  const images = useRef();
  const [widthOfImages, setWidthOfImages] = useState(null);

  useEffect(() => {
    const child = images.current.children[0].querySelector('img');
    const items = images.current.children;
    const count = items.length;
    const width = child.offsetWidth;
    setWidthOfImages(width * count);

    //Hack to create animation
    let styleSheet =
      typeof window != 'undefined' ? document.styleSheets[0] : null;
    let keyframes = `@keyframes animationFunNew {
        0% {margin-left: 0px}
        100% {margin-left: -${widthOfImages / 2}px}
    }`;

    typeof window != 'undefined'
      ? styleSheet.insertRule(keyframes, styleSheet.cssRules.length)
      : null;
  });

  let style = {
    animationName: 'animationFunNew',
    animationTimingFunction: 'linear',
    animationDuration: '60s',
    animationIterationCount: 'infinite',
  };

  return (
    <section className="mt-20 overflow-hidden">
      <div
        style={{ width: widthOfImages + 'px' }}
        className="photobanner"
        ref={images}
      >
        {chosenOnes.map((image, index) => {
          const img = getImage(image);
          return (
            <GatsbyImage
              style={index === 0 ? style : ''}
              image={img}
              alt="slider image"
              className="object-cover w-48 h-32 md:h-64 md:w-96"
            />
          );
        })}
        {chosenOnes.map((image) => {
          const img = getImage(image);
          return (
            <GatsbyImage
              image={img}
              alt="slider image"
              className="object-cover w-48 h-32 md:h-64 md:w-96"
            />
          );
        })}
      </div>
    </section>
  );
}
