import { Link } from 'gatsby';
import React from 'react';

export default function Breadcrumb({ title, link }) {
  return (
    <li className="breadcrumb__item">
      <Link to={link} className="breadcrumb__link">
        {title}
      </Link>
      <span className="breadcrumb__separator" aria-hidden="true">
        {' > '}
      </span>
    </li>
  );
}
